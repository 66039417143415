import { render, staticRenderFns } from "./EventTimeline.vue?vue&type=template&id=4f999428&scoped=true"
import script from "./EventTimeline.vue?vue&type=script&lang=js"
export * from "./EventTimeline.vue?vue&type=script&lang=js"
import style0 from "./EventTimeline.vue?vue&type=style&index=0&id=4f999428&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f999428",
  null
  
)

export default component.exports