<template>
  <div class="event-card">
    <!-- Header -->
    <div class="heading">
      Event Timeline
      <div class="hLine"></div>
    </div>

    <div class="content-wrapper">
      <!-- Timeline events -->
      <div class="timeline">
        <div class="event" v-for="(event, index) in events" :key="index">
          <div class="event-img">
            <img
              :src="getImgSrc(event.imgName)"
              :class="
                event.active ? event.activeImgClass : event.inactiveImgClass
              "
              alt="event image"
            />
          </div>
          <div :class="event.active ? 'eventTitleActive' : 'eventTitle'">
            {{ event.title }}
          </div>
          <div
            class="event-date"
            :class="event.active ? 'eventDateActive' : 'eventDate'"
            @mouseenter="showCalendar(index)"
            @mouseleave="hideCalendar(index)"
          >
            {{ event.date }}
            <!-- Calendar tooltip -->
            <div class="calendar-popup" v-if="event.showCalendar">
              <button @click="addCalendar(event.dateStr, event.title)">
                add to calendar
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Slider (read-only) -->
      <div class="slider-container">
        <input
          type="range"
          min="1"
          max="100"
          :value="value"
          class="slider"
          :style="sliderStyle"
          disabled
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "EventTimeline",
  data() {
    return {
      value: 4,
      kickOff: false,
      goldBall: false,
      graduation: false,
      endDate: false,
      events: [],
    };
  },
  computed: {
    // Example: format dates as "Apr 1" etc.
    getdates() {
      return {
        kickOff: moment("2025-04-01").format("MMM D"),
        kickOffStr: "20250401T1000",
        goldBall: moment("2025-04-05").format("MMM D"),
        goldBallStr: "20250405T1000",
        grad: moment("2025-04-10").format("MMM D"),
        gradStr: "20250410T1000",
        eventEnd: moment("2025-04-15").format("MMM D"),
        eventEndStr: "20250415T1000",
        active: moment("2025-04-05").format("MMM D"),
      };
    },
    sliderStyle() {
      // Example background fill for horizontal slider
      if (this.value > 40) {
        return {
          background: `linear-gradient(to right, #83008B 0%, #E71953 35%, #FBB927 ${this.value}%, #d3d3d3 ${this.value}%, #d3d3d3 75%, #d3d3d3 100%)`,
        };
      } else {
        return {
          background: `linear-gradient(to right, #83008B 0%, #FBB927 ${this.value}%, #d3d3d3 ${this.value}%, #d3d3d3 100%)`,
        };
      }
    },
  },
  methods: {
    getImgSrc(src) {
      return require("@/assets/publicDonationPage/timelineEvent/" + src);
    },
    addCalendar(date, title) {
      const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        "Wizfit Challenge " + title
      )}&dates=${date}/${date}`;
      window.open(url, "_blank");
    },
    showCalendar(index) {
      this.$set(this.events[index], "showCalendar", true);
    },
    hideCalendar(index) {
      this.$set(this.events[index], "showCalendar", false);
    },
    getActiveState() {
      if (this.getdates.kickOff === this.getdates.active) {
        this.kickOff = true;
        this.value = 10;
      } else if (this.getdates.goldBall === this.getdates.active) {
        this.goldBall = true;
        this.value = 38;
      } else if (this.getdates.grad === this.getdates.active) {
        this.graduation = true;
        this.value = 65;
      } else {
        this.endDate = true;
        this.value = 0;
      }
    },
  },
  mounted() {
    this.getActiveState();
    this.events = [
      {
        title: "Kick Off",
        date: this.getdates.kickOff,
        dateStr: this.getdates.kickOffStr,
        imgName: this.kickOff ? "kickOff.gif" : "kickOff.png",
        active: this.kickOff,
        activeImgClass: "kickGifClass",
        inactiveImgClass: "kickPngClass",
        showCalendar: false,
      },
      {
        title: "Platinum Ball Giveaway",
        date: this.getdates.goldBall,
        dateStr: this.getdates.goldBallStr,
        imgName: this.goldBall ? "goldBall.gif" : "goldBall.png",
        active: this.goldBall,
        activeImgClass: "goldGifClass",
        inactiveImgClass: "goldPngClass",
        showCalendar: false,
      },
      {
        title: "Graduation",
        date: this.getdates.grad,
        dateStr: this.getdates.gradStr,
        imgName: this.graduation ? "graduation.gif" : "graduation.png",
        active: this.graduation,
        activeImgClass: "graduationGifClass",
        inactiveImgClass: "graduationPngClass",
        showCalendar: false,
      },
      {
        title: "Event Ends",
        date: this.getdates.eventEnd,
        dateStr: this.getdates.eventEndStr,
        imgName: this.endDate ? "eventEnd.gif" : "eventEnd.png",
        active: this.endDate,
        activeImgClass: "endGifClass",
        inactiveImgClass: "endPngClass",
        showCalendar: false,
      },
    ];
  },
};
</script>

<style scoped>
/* Container */
.event-card {
  background: white;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    overflow: auto;
    border-radius: 20px;
    padding: 20px;
    max-width: 100vw;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Header */
.heading {
  font-size: 25px;
  font-family: "Roboto Slab", serif;
  font-weight: 600;
  color: #2c1963;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.hLine {
  background-color: #2c1963;
  width: 50px;
  height: 4px;
  border-radius: 20px;
  margin-left: 15px;
}

/* Default: flex column for large screens */
.content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Timeline flex container (row layout on large screens) */
.timeline {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: nowrap;

}

/* Each event */
.event {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  position: relative;
}

.event-img img {
  /* max-width: 100px; */
  width: 10vw;
  height: auto;
}

/* Titles */
.eventTitle {
  margin-top: 10px;
  font-family: "Roboto Slab", serif;
  flex-wrap: nowrap;
}
.eventTitleActive {
  margin-top: 10px;
  font-family: "Roboto Slab", serif;
  color: #38227a;
  font-size: clamp(0.7rem, 2vw, 1.2rem);

}

/* Date element and tooltip */
.event-date {
  margin-top: 5px;
  border: 1px solid #757575;
  border-radius: 6px;
  padding: 5px 10px;
  color: #757575;
  cursor: pointer;
  position: relative;
  font-size: clamp(0.7rem, 2vw, 1.2rem);
}
.eventDateActive {
  background-color: #38227a;
  color: #ffffff;
  border: 1px solid #757575;
}
.calendar-popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  background: #fff;
  border: 1px solid #512e96;
  border-radius: 6px;
  padding: 5px;
  z-index: 10;
}
.calendar-popup button {
  background: transparent;
  border: 1px solid #512e96;
  border-radius: 6px;
  padding: 5px;
  color: #512e96;
  cursor: pointer;
}

/* Slider (horizontal by default) */
.slider-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 30px;
  border-radius: 20px;
  background: #d3d3d3;
  outline: none;
}
/* Slider thumb */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 70px;
  height: 60px;
  border: 0;
  background: url("../../../../assets/publicDonationPage/football.svg")
    no-repeat center;
  background-size: contain;
  cursor: pointer;
}
.slider::-moz-range-thumb {
  width: 70px;
  height: 60px;
  border: 0;
  background: url("../../../../assets/publicDonationPage/football.svg")
    no-repeat center;
  background-size: contain;
  cursor: pointer;
}

/* Image classes */
.kickGifClass {
  max-width: 100px;
}
.kickPngClass {
  max-width: 55px;
}
.goldGifClass {
  max-width: 100px;
}
.goldPngClass {
  max-width: 70px;
}
.graduationGifClass {
  max-width: 90px;
}
.graduationPngClass {
  max-width: 70px;
}
.endGifClass {
  max-width: 110px;
}
.endPngClass {
  max-width: 65px;
}

/* ----------- SMALL SCREEN (GRID) ----------- */
@media (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  
  .hLine {
    width: 30px;
    height: 3px;
  }
  .event-card {
    display: grid;
    flex-direction: column;
    /* align-items: center; */
    padding: 20px;
    max-width: 100vw;
    height: auto;
    padding: 20px;
    margin: 10px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 20px;
  background: #d3d3d3;
  outline: none;
  margin: 10px;
  margin-top: none;
}
  /* The timeline is a single grid item in column 1, 
     spanning all rows (row 1 / -1) if needed. */
  .timeline {
    grid-area: event;
    grid-row: 1;
    grid-column: 1 / -1; /* so it fills from top to bottom of the grid */
    display: flex;
    flex-direction: row; /* stack events vertically */
  }

  /* Each event */
  .event {
    /* grid-area: event; */
    align-items: center;
    margin: 10px;
    position: relative;
  }

  .event-img img {
    max-width: 50px;
  }

  /* The slider container is in column 2, spanning all rows as well. */
  .slider-container {
    grid-area: slider;
    align-items: center; /* let the container stretch to match the timeline height */
  }


  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    border: 0;
    background: url("../../../../assets/publicDonationPage/football.svg")
      no-repeat center;
    background-size: contain;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    width: 30px;
    height: 30px;
    border: 0;
    background: url("../../../../assets/publicDonationPage/football.svg")
      no-repeat center;
    background-size: contain;
    cursor: pointer;
  }
} 
</style>

<!-- 
@media (max-width: 600px) {
  .heading {
    font-size: 20px;
  }
  .hLine {
    width: 30px;
    height: 3px;
  }
  .event-card {
    display: grid;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    max-width: 100vw;
    height: auto;
    margin: 10px auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  /* Use a grid with two columns: events (col 1) and slider (col 2) */
  .content-wrapper {
    display: grid;
    grid-template-areas: "event slider";
    grid-template-columns: auto auto;
    column-gap: 20px;
    position: relative;
  }

  /* The timeline is a single grid item in column 1, 
     spanning all rows (row 1 / -1) if needed. */
  .timeline {
    grid-area: event;
    grid-column: 1;
    grid-row: 1 / -1; /* so it fills from top to bottom of the grid */
    display: flex;
    flex-direction: column; /* stack events vertically */
  }

  /* Each event */
  .event {
    /* grid-area: event; */
    align-items: center;
    margin: 10px;
    position: relative;
  }

  .event-img img {
    max-width: 50px;
  }

  /* The slider container is in column 2, spanning all rows as well. */
  .slider-container {
    grid-area: slider;
    align-items: center; /* let the container stretch to match the timeline height */
  }

  /* Rotate the slider to be vertical. 
     Setting height: 100% will make it fill the container’s height. */
  .slider {
    transform: rotate(-270deg);
  }
  /* Slider thumb */
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 60px;
    height: 60px;
    border: 0;
    background: url("../../../../assets/publicDonationPage/football.svg")
      no-repeat center;
    background-size: contain;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    width: 60px;
    height: 60px;
    border: 0;
    background: url("../../../../assets/publicDonationPage/football.svg")
      no-repeat center;
    background-size: contain;
    cursor: pointer;
  }
} -->